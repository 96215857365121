<template>
  <div class="table-responsive">
    <table class="table table-hover text-nowrap">
      <thead>
        <tr>
          <th>Payment ID</th>
          <th>Paid On</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="i in 3"
          :key="i"
        >
          <td>item</td>
          <td>item</td>
          <td>item</td>
        </tr>
      </tbody>
    </table>
    <a-pagination
      v-if="total"
      class="pagination-right"
      :default-current="1"
      :total="total"
      @change="pagination($event)"
    />
  </div>
</template>

<script>
import axios from '../axios'
import { Modal } from 'ant-design-vue'

export default {
  data() {
    return {
      step: 10,
      skip: 0,
      total: null,
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    pagination(int) {
      this.skip = 0
      this.skip += this.step * int - this.step
      this.loadItems()
    },
    loadItems() {
      //   axios
      //     .get(`user?take=${this.step}&skip=${this.skip}`)
      //     .then((res) => {
      //       this.items = res.data.data
      //       this.total = res.data.count
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
    },
    // approveConfirm(id) {
    //   let vm = this
    //   Modal.confirm({
    //     title: 'Do you want to approve this application?',
    //     onOk() {
    //       vm.approve(id)
    //     },
    //     class: 'test',
    //   })
    // },
    // rejectConfirm(id) {
    //   let vm = this
    //   Modal.confirm({
    //     title: 'Do you want to reject this application?',
    //     content: (
    //       <div class="d-flex flex-column" width="100%">
    //         <span>Enter the reason:</span>
    //         <input width="100%" id="input" />
    //       </div>
    //     ),
    //     onOk() {
    //       const reason = document.querySelector('#input').value
    //       vm.reject(id, reason)
    //     },
    //     class: 'test',
    //   })
    // },
    // approve(id) {
    //   axios
    //     .post(`registration/${id}/approve`)
    //     .then((res) => {
    //       console.log(res)
    //       this.success()
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //       this.danger()
    //     })
    // },
    // reject(id, reason) {
    //   axios
    //     .post(`registration/${id}/reject`, {
    //       reason,
    //     })
    //     .then(() => {
    //       this.reason = null
    //       this.success()
    //     })
    //     .catch((err) => {
    //       this.reason = null
    //       console.log(err)
    //       this.danger()
    //     })
    // },
    // success() {
    //   const vm = this
    //   Modal.success({
    //     title: 'Operation completed successfully',
    //     onOk() {
    //       vm.loadItems()
    //     },
    //   })
    // },
    // danger() {
    //   Modal.error({
    //     title: 'Something went wrong, please update and try again',
    //   })
    // },
  },
}
</script>

<style scoped>
.pagination-right {
  text-align: right!important;
  margin-right: 86px!important;
}
</style>